import { navigate } from 'gatsby';
import tw from 'twin.macro';
import Content from './Content';

const PageContent = ({ title, content }) => (
  <div css={tw`px-6 my-6 sm:my-16 xl:px-48`}>
    <h1 className="underlined big" css={tw`font-serif text-3xl sm:text-5xl`}>
      {title}
    </h1>
    <Content content={content} />
    <div
      css={tw`flex flex-col items-center p-4 rounded-md shadow-md sm:p-8 sm:flex-row bg-brand-bluegray`}
    >
      <div css={tw`flex-1`}>
        <h1
          className="underlined"
          css={tw`font-serif text-3xl text-center sm:text-5xl sm:text-left`}
        >
          Interesse geweckt?
        </h1>
        <p css={tw`max-w-2xl mt-4 text-xl text-gray-500`}>
          Schicken Sie uns eine kostenlose Anfrage und kriegen Sie ein
          unverbindliches Angebot von unseren Experten,
        </p>
      </div>
      <button
        css={tw`px-6 py-2 mt-8 text-2xl font-medium rounded-md sm:mt-0 sm:mx-12 bg-brand-orange hover:bg-brand-darkOrange`}
        type="button"
        onClick={() => {
          navigate('/kontakt');
        }}
      >
        Anfrage
      </button>
    </div>
  </div>
);

export default PageContent;
